import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getAllBusinesses } from "../../services/authService";
import moment from "moment";

import "./admin.css";

const Admin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [businesses, setBusinesses] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const businessesPerPage = 10;

  useEffect(() => {
    async function fetchBusinesses() {
      try {
        const bss = await getAllBusinesses();
        console.log("bss", bss);
        setBusinesses(bss.businesses);
      } catch (error) {
        console.error("Failed to fetch businesses:", error);
      }
    }

    fetchBusinesses();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = params.get("search") || "";
    setSearchTerm(query);
  }, [location.search]);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchTerm(query);
    navigate(`?search=${query}`);
  };

  // Filtered businesses based on the search term
  const filteredBusinesses = businesses?.filter((business) =>
    business.businessName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Get current businesses for pagination
  const indexOfLastBusiness = currentPage * businessesPerPage;
  const indexOfFirstBusiness = indexOfLastBusiness - businessesPerPage;
  const currentBusinesses = filteredBusinesses?.slice(
    indexOfFirstBusiness,
    indexOfLastBusiness
  );

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const format = "MMMM Do YYYY, h:mmA";
  // const formattedDate = moment(createdAt).format(format);

  return (
    <div className="admin_page">
      <h1>Admin Dashboard</h1>
      <div className="summary">
        <p>Total Businesses: {filteredBusinesses?.length}</p>
      </div>
      <div className="search_bar">
        <input
          type="text"
          placeholder="Search businesses by name..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      <div className="business_list">
        {currentBusinesses?.length > 0 ? (
          currentBusinesses.map((business) => (
            <div key={business._id} className="business_card">
              <img
                src={business.photo}
                alt={business.businessName}
                className="business_image"
              />
              <h2>{business.businessName}</h2>
              <p>
                <strong>Email:</strong> {business.businessEmail}
              </p>
              <p>
                <strong>Id:</strong> {business._id}
              </p>
              <p>
                <strong>Address:</strong> {business.businessAddress}
              </p>
              <p>
                <strong>Phone:</strong> {business.businessPhone}
              </p>
              <p>
                <strong>Industry:</strong> {business.industry}
              </p>
              <p>
                <strong>Country:</strong> {business.country}
              </p>

              <h3>Business Owner</h3>
              <p>
                <strong>Name:</strong>{" "}
                {`${business.businessOwner.firstName} ${business.businessOwner.lastName}`}
              </p>
              <p>
                <strong>Email:</strong> {business.businessOwner.email}
              </p>

              <h3>Sales Representatives</h3>
              {business.salesRep.map((rep, index) => (
                <div key={index} className="sales_rep">
                  <p>
                    <strong>Name:</strong> {`${rep.firstName} ${rep.lastName}`}
                  </p>
                  <p>
                    <strong>Email:</strong> {rep.email}
                  </p>
                </div>
              ))}

              <h3>Subscription Details</h3>
              <p>
                <strong>Type:</strong> {business.subscription.subscriptionType}
              </p>
              <p>
                <strong>Plan:</strong> {business.subscription.plan}
              </p>
              <p>
                <strong>Next Due Date:</strong>{" "}
                {moment(business.subscription.nextDueDate).format(format)}
              </p>

              <h3>Business Metrics</h3>
              <p>
                <strong>Products:</strong> {business.productCount}
              </p>
              <p>
                <strong>Last Product Update:</strong>{" "}
                {business.lastProductUpdate
                  ? moment(business.lastProductUpdate).format(format)
                  : "N/A"}
              </p>
              <p>
                <strong>Checkout Sessions:</strong> {business.checkoutCount}
              </p>
              <p>
                <strong>Last Checkout Update:</strong>{" "}
                {business.lastCheckoutUpdate
                  ? moment(business.lastCheckoutUpdate).format(format)
                  : "N/A"}
              </p>
              <p>
                <strong>Product Groups:</strong> {business.productGroupCount}
              </p>
              <p>
                <strong>Last Product Group Update:</strong>{" "}
                {business.lastProductGroupUpdate
                  ? moment(business.lastProductGroupUpdate).format(format)
                  : "N/A"}
              </p>
            </div>
          ))
        ) : (
          <p>No businesses found.</p>
        )}
      </div>
      <Pagination
        businessesPerPage={businessesPerPage}
        totalBusinesses={filteredBusinesses?.length}
        paginate={paginate}
        currentPage={currentPage}
      />
    </div>
  );
};

const Pagination = ({
  businessesPerPage,
  totalBusinesses,
  paginate,
  currentPage,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalBusinesses / businessesPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav className="pagination">
      <ul>
        {pageNumbers.map((number) => (
          <li key={number} className={number === currentPage ? "active" : ""}>
            <a onClick={() => paginate(number)} href="#!">
              {number}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Admin;