import React, { useEffect, useState } from "react";
import "./businessSummary.scss";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  CALC_CATEGORY,
  CALC_OUTOFSTOCK_SINGLE_PRODUCTS,
  CALC_OUTOFSTOCK_GROUP_PRODUCTS,
  CALC_STORE_VALUE_BY_PRICE,
  CALC_STORE_VALUE_BY_COST,
  getLowProducts,
  getSalesByYear,
  getTopProducts,
  selectCategory,
  selectOutOfStockSingleProducts,
  selectTotalStoreValueByCost,
  selectTotalStoreValueByPrice,
  selectOutOfStockGroupProducts,
} from "../../redux/features/product/productSlice";
import {
  selectLoggedInBusinessOwner,
  selectUser,
} from "../../redux/features/auth/authSlice";
import dash1 from "../../assets/home/dash1.svg";
import dash2 from "../../assets/home/dash2.svg";
import dash3 from "../../assets/home/dash3.svg";
import dash4 from "../../assets/home/dash4.svg";
import eyeIcon from "../../assets/home/show.svg";
import OurChart from "./Chart";
import { getAllCheckouts } from "../../redux/features/cart/cartSlice";

// Format Amount
export const formatNumbers = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const BusinessSummary = ({ products, productGroups, admin }) => {
  const dispatch = useDispatch();
  const totalStoreValueByPrice = useSelector(selectTotalStoreValueByPrice);
  const totalStoreValueByCost = useSelector(selectTotalStoreValueByCost);
  const outOfStockSingleProducts = useSelector(selectOutOfStockSingleProducts);
  const outOfStockGroupProducts = useSelector(selectOutOfStockGroupProducts);
  const category = useSelector(selectCategory);
  const { topProducts } = useSelector((state) => state.topProducts);
  const { lowProducts } = useSelector((state) => state.lowProducts);
  const { salesByYear } = useSelector((state) => state.salesByYear);
  
  const [isStoreValueVisible, setIsStoreValueVisible] = useState(false);
  const currentUser = useSelector(selectUser);
  const { allCheckouts } = useSelector((state) => state.checkouts);

  let filteredTopProducts = [];
  let filteredProductGroups = [];

  filteredTopProducts = topProducts.products?.filter((pr) => {
    return pr._id !== null;
  });

  filteredProductGroups = topProducts.productGroups?.filter((pr) => {
    return pr._id !== null;
  });
  
  const uniqueYears = [
    ...new Set(
      allCheckouts?.map((item) => new Date(item.createdAt).getFullYear())
    ),
  ];
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);

  const handleYearChange = (e) => {
    setYear(e.target.value);
  };

  useEffect(() => {
    dispatch(getAllCheckouts({}));
    dispatch(getSalesByYear(year));
  }, [dispatch, year]);

  const toggleStoreValueVisibility = () => {
    setIsStoreValueVisible((prev) => !prev);
  };

  useEffect(() => {
    dispatch(CALC_STORE_VALUE_BY_PRICE(products));
    dispatch(CALC_STORE_VALUE_BY_COST(products));
    dispatch(CALC_OUTOFSTOCK_SINGLE_PRODUCTS(products));
    dispatch(CALC_OUTOFSTOCK_GROUP_PRODUCTS(productGroups));
    dispatch(getTopProducts());
    dispatch(getLowProducts());
    dispatch(CALC_CATEGORY(products));
  }, [dispatch, products, productGroups]);

  const formatter = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    }).format(amount);
  };

  return (
    <div className="store-summary items">
      <div className="item info_card total_products">
        <img src={dash1} alt="total products" />
        <h3>{products.length}</h3>
        <p>Total Products</p>
      </div>
      <div className="item info_card total_category">
        <img src={dash2} alt="total category" />
        <h3>{category.length}</h3>
        <p>Total Categories</p>
      </div>
      <div className="item sales_graph">
        <div className="chart-container-header">
          <h1>Sales & Profit</h1>
          <select onChange={handleYearChange} value={year}>
            {uniqueYears?.map((yr, index) => {
              return (
                <option key={index} value={yr}>
                  {yr}
                </option>
              );
            })}
          </select>
        </div>
        <div className="graph_container">
          {admin || currentUser?.permissions?.seeBusinessFinances ? (
            <OurChart sales={salesByYear} />
          ) : null}
        </div>
      </div>
      <div className="item top_selling">
        <div className="item-header">
          <h3>Top Selling Product</h3>
          <Link to="/dashboard">See All</Link>
        </div>
        <div className="item-body">
          <table>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Name</th>
                <th>Sold Quantity</th>
                {/* <th>Price</th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <h1>Single Products</h1>
              </tr>
              {filteredTopProducts && filteredTopProducts.length > 0 ? (
                filteredTopProducts.map((product, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{product?.name}</td>
                    <td>{product?.total_sales}</td>
                    {/* <td>{product?.price}</td> */}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3">No top-selling Single Products available</td>
                </tr>
              )}

              <tr>
                <h1>Group Products</h1>
              </tr>
              {filteredProductGroups && filteredProductGroups.length > 0 ? (
                filteredProductGroups.map((product, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{product?.name}</td>
                    <td>{product?.total_sales}</td>
                    {/* <td>{product?.price}</td> */}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3">No top-selling Group Products available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="item info_card out_of_stock">
        <Link to="/inventory/out-of-stock">
          <img src={dash3} alt="stock_out" />
          <div className="out-of-stock-values">
            <div className="value">
              <h3>{outOfStockSingleProducts}</h3>
              <p>Single Products</p>
            </div>
            <div className="value">
              <h3>{outOfStockGroupProducts}</h3>
              <p>Group Products</p>
            </div>
          </div>
          <p>Out of Stock Products</p>
        </Link>
      </div>
      {/* <div className="item info_card out_of_stock">
        <img src={dash3} alt="stock_out" />
        <h3>{outOfStockSingleProducts}</h3>
        <p>Out of stock Products</p>
      </div> */}
      {/* <div
        className="item info_card store_value"
        onClick={toggleStoreValueVisibility}
      >
        <img src={dash4} alt="total products" />
        <h3>
          {admin || currentUser?.permissions?.seeBusinessFinances ? (
            isStoreValueVisible ? (
              `${formatter(totalStoreValueByPrice)}`
            ) : (
              <img src={eyeIcon} alt="show value" />
            )
          ) : (
            "Unavailable"
          )}
        </h3>
        <p>Total store value</p>
      </div> */}
      <div
        className="item info_card store_value"
        onClick={toggleStoreValueVisibility}
      >
        <img src={dash4} alt="total products" />
        <div className="store-values">
          {admin || currentUser?.permissions?.seeBusinessFinances ? (
            <>
              <div className="value">
                <h3>
                  {isStoreValueVisible ? (
                    `${formatter(totalStoreValueByPrice)}`
                  ) : (
                    <img src={eyeIcon} alt="show value" />
                  )}
                </h3>
                <p>By Price</p>
              </div>
              <div className="value">
                <h3>
                  {isStoreValueVisible ? (
                    `${formatter(totalStoreValueByCost)}`
                  ) : (
                    <img src={eyeIcon} alt="show value" />
                  )}
                </h3>
                <p>By Cost</p>
              </div>
            </>
          ) : (
            <h3>Unavailable</h3>
          )}
        </div>
        <p>Total store value</p>
      </div>
      <div className="item low_products">
        <div className="item-header">
          <h3>Low quantity Products</h3>
          <Link to="/dashboard">See All</Link>
        </div>
        <div className="item-body">
          <table>
            <thead>
              <tr>
                <th>product</th>
                <th>Remaining Quantity</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <h1>Single Products</h1>
              </tr>
              {lowProducts && lowProducts.products?.length > 0 ? (
                lowProducts.products.map((product, index) => (
                  <tr key={index}>
                    <td>{product.products[0].name}</td>
                    <td>{product.product_quantity}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="2">No low item(s) for Single Products</td>
                </tr>
              )}

              <tr>
                <h1>Group Products</h1>
              </tr>
              {lowProducts && lowProducts.productGroups?.length > 0 ? (
                lowProducts.productGroups.map((product, index) => (
                  <tr key={index}>
                    <td>{product.groupName}</td>
                    <td>{product.product_quantity}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="2">No low item(s) for Group Products</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default BusinessSummary;
