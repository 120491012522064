import { useParams } from "react-router-dom";
import arrowDown from "../../assets/home/arrowdown.svg";
import arrowUp from "../../assets/home/arrowUp.svg";
import moment from "moment";
import { useState } from "react";
import { Tooltip } from "antd";

export default function FulFilmentTable({
  items,
  handleAmountChange,
  handleUpdatePayment,
  amountPaid,
}) {
  const [showBody, setShowBody] = useState({});

  const handleShowBody = (id) => {
    setShowBody((prev) => {
      const newState = { ...prev };
      newState[id] = !newState[id];
      return newState;
    });
  };

  const formatter = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    }).format(amount);
  };

  const shortenText = (text, n) => {
    if (text.length > n) {
      const shortenedText = text.substring(0, n).concat("...");
      return shortenedText;
    }
    return text;
  };

  return (
    <table>
      <thead>
        <tr>
          <th>S/N</th>
          <th>Date</th>
          <th>Customer</th>
          <th>Amount Paid</th>
          <th>Balance</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {items?.map((item, index) => {
          const { customer, payment, createdAt, _id, paymentStatus } = item;
          const formattedDate = moment(createdAt).format("DD-MM-YYYY h:mmA");

          return (
            <>
              <tr key={index}>
                <td onClick={() => handleShowBody(_id)}>{index + 1}</td>
                <td onClick={() => handleShowBody(_id)}>
                  <div className="item_s_date">{formattedDate}</div>
                </td>
                <td onClick={() => handleShowBody(_id)}>
                  <div className="item_name">
                    <Tooltip title={customer.name}>
                      {shortenText(customer.name, 16)}
                    </Tooltip>
                  </div>
                </td>
                <td onClick={() => handleShowBody(_id)}>
                  <div className="item_name">
                    <Tooltip title={payment?.paymentDetails?.amountPaid}>
                      {formatter(payment?.paymentDetails?.amountPaid)}
                    </Tooltip>
                  </div>
                </td>
                <td onClick={() => handleShowBody(_id)}>
                  {formatter(payment?.paymentDetails?.balance)}
                </td>
                <td onClick={() => handleShowBody(_id)}>
                  {payment?.paymentStatus}
                </td>
                <td className="icons">
                  <div className="">
                    <span className="actions-spans">
                      <span
                        className="arrow-btn"
                        onClick={() => handleShowBody(_id)}
                      >
                        <img
                          src={showBody[_id] ? arrowUp : arrowDown}
                          alt="arrow down"
                        />
                      </span>
                      {payment?.paymentStatus !== "completed" && (
                        <span className="fufilment_field">
                          <input
                            type="number"
                            value={amountPaid[_id] || ""}
                            onChange={(e) =>
                              handleAmountChange(_id, e.target.value)
                            }
                            placeholder="Enter amount"
                          />
                          <button onClick={() => handleUpdatePayment(_id)}>
                            Update
                          </button>
                        </span>
                      )}
                    </span>
                  </div>
                </td>
              </tr>
              {showBody[_id] && (
                <tr className="dynamically-display">
                  <td colSpan="7">
                    <div className="dynamic-content">
                      <div className="dynamically-display-header">
                        <strong>Product Name</strong>
                        <strong className="center">Quantity</strong>
                        <strong className="right">Selling Price</strong>
                      </div>
                      {item.items.map((item, idx) => (
                        <div key={idx} className="dynamically-display-body">
                          <span>{item.name}</span>
                          <span className="center">{item.quantity}</span>
                          <span className="right">{formatter(item.price)}</span>
                        </div>
                      ))}
                      <div className="payments_header">
                        <strong>Part Payments</strong>
                        <strong>Dates</strong>
                      </div>
                      {payment.paymentDetails.paymentParts.map((part, idx) => (
                        <div key={idx} className="payment_parts">
                          <span>{formatter(part.amountPaid)}</span>
                          <span className="payment_dates">
                            {moment(part.datePaid).format("DD-MM-YYYY h:mmA")}
                          </span>
                        </div>
                      ))}
                    </div>
                  </td>
                </tr>
              )}
            </>
          );
        })}
      </tbody>
    </table>
  );
}
