import React, { useState } from "react";
import "./return.css";
import { useParams, useLocation } from "react-router-dom";
import { Tooltip } from "antd";
import {
  getCheckouts,
  returnedGoods,
} from "../../../redux/features/cart/cartSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getProducts } from "../../../redux/features/product/productSlice";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function ReturnFunction({
  handleCancel,
  items,
  admin,
  currentUser,
}) {
  const query = useQuery();
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState([]);

  const id = query.get("id");

  const itemToReturn = items.filter((item) => {
    return item._id === id;
  });

  //   console.log("item to return", itemToReturn);
  // console.log("selected items", selectedItems);

  const formatter = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    }).format(amount);
  };

  const handleSelect = (itemId) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.includes(itemId)
        ? prevSelectedItems.filter((id) => id !== itemId)
        : [...prevSelectedItems, itemId]
    );
  };

  const handleReturnSelected = async () => {
    // Handle the return of selected items
    const itemsToReturn = itemToReturn[0].items.filter((item) =>
      selectedItems.includes(item._id)
    );
    const formData = {
      itemsToReturn: itemsToReturn,
    };

    // console.log("Returning selected items:", itemsToReturn);
    await handleCancel();
    if (itemsToReturn.length > 0) {
      await dispatch(returnedGoods({ id, formData }));
      await dispatch(getProducts());
      dispatch(getCheckouts({}));
    } else {
      toast.error("Please select the item(s) to be returned.");
    }
  };

  const handleReturnAllItems = async () => {
    // Handle the return of selected items
    const itemsToReturn = itemToReturn[0].items;
    const formData = {
      itemsToReturn: itemsToReturn,
    };

    // console.log("Returning selected items:", itemsToReturn);
    await handleCancel();
    if (itemsToReturn.length > 0) {
      await dispatch(returnedGoods({ id, formData }));
      await dispatch(getProducts());
      dispatch(getCheckouts({}));
    } else {
      toast.error("Please select the item(s) to be returned.");
    }
  };

  return (
    <div className="return_content_body">
      <span onClick={() => handleCancel()}>close</span>
      <div className="items_to_return">
        <div className="return_header">
          <h3>Items to return</h3>
        </div>
        <div className="current_items">
          <table>
            <thead>
              <tr>
                <th>Select</th>
                <th>Product Name</th>
                <th>Selling Price</th>
                <th>Cost Price</th>
                <th>Quantity</th>
                <th>Profit</th>
              </tr>
            </thead>
            <tbody>
              {itemToReturn[0] &&
                itemToReturn[0].items.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedItems.includes(item._id)}
                        onChange={() => handleSelect(item._id)}
                      />
                    </td>
                    <td>{item.name}</td>
                    <td>{formatter(item.price)}</td>
                    <td>
                      {admin || currentUser?.permissions?.seeBusinessFinances
                        ? formatter(item.cost)
                        : "Unavailable"}
                    </td>
                    <td>{item.quantity}</td>
                    <td>
                      {admin || currentUser?.permissions?.seeBusinessFinances
                        ? formatter(
                            item.price * item.quantity -
                              item.cost * item.quantity
                          )
                        : "Unavailable"}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <div className="return_item_buttons">
          <button onClick={handleReturnSelected}>
            Return {selectedItems.length} item(s)
          </button>
          <button onClick={handleReturnAllItems} className="return_all_btn">
            Return All
          </button>
        </div>
      </div>
    </div>
  );
}
