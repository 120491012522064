import React, { useEffect, useState } from "react";
import { SpinnerImg } from "../../loader/Loader";
import "../productList/productList.scss";
import { AiOutlineEye } from "react-icons/ai";
import { confirmAlert } from "react-confirm-alert";
import { Tooltip } from "antd";
import Search from "../../search/Search";
import { useDispatch, useSelector } from "react-redux";
import {
  FILTER_PRODUCT_GROUPS,
  selectFilteredProductGroups,
} from "../../../redux/features/product/filterSlice";
import {
  getAllProductGroups,
  deleteGroupItem,
} from "../../../redux/features/product/productSlice";
import ReactPaginate from "react-paginate";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link, useNavigate } from "react-router-dom";
import deleteIcon from "../../../assets/home/delete-icon.svg";
import editIcon from "../../../assets/home/edit-icon.svg";
import InventoryHeader from "../../inventoryHeader/InventoryHeader";
import { selectUser } from "../../../redux/features/auth/authSlice";
import moment from "moment";

const ProductGroupList = ({ productGroups, isLoading, admin }) => {
  const [search, setSearch] = useState("");
  // const filteredProducts = useSelector(selectFilteredPoducts);
  const navigate = useNavigate();
  const filteredProductGroups = useSelector(selectFilteredProductGroups);
  const currentUser = useSelector(selectUser);

  const dispatch = useDispatch();

  const shortenText = (text, n) => {
    if (text.length > n) {
      const shortenedText = text.substring(0, n).concat("...");
      return shortenedText;
    }
    return text;
  };

  //   Begin Pagination
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;

  // dispatch(getOutOfStock());
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(filteredProductGroups.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(filteredProductGroups.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, filteredProductGroups]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredProductGroups.length;
    setItemOffset(newOffset);
  };
  //   End Pagination

  const delProduct = async (id) => {
    await dispatch(deleteGroupItem(id));
    await dispatch(getAllProductGroups());
  };

  useEffect(() => {
    dispatch(FILTER_PRODUCT_GROUPS({ productGroups, search }));
  }, [productGroups, search, dispatch]);

  const confirmDelete = (id) => {
    confirmAlert({
      title: "Delete Product",
      message: "Are you sure you want to delete this product.",
      buttons: [
        {
          label: "Delete",
          onClick: () => delProduct(id),
        },
        {
          label: "Cancel",
          // onClick: () => alert('Click No')
        },
      ],
    });
  };

  return (
    <div className="product-list">
      <div className="table-container table">
        <InventoryHeader
          search={search}
          handleSearchChange={(e) => setSearch(e.target.value)}
        />

        {isLoading && <SpinnerImg />}

        <div className="table product-group-list">
          {!isLoading && productGroups.length === 0 ? (
            <p className="no-products-p">
              {" "}
              -- No product group found, please add a product group{" "}
            </p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Date Created</th>
                  <th>Name</th>
                  <th>Category</th>

                  <th>quantity</th>

                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {currentItems.map((productGroup, index) => {
                  const {
                    _id,
                    price,
                    groupName,
                    category,
                    description,
                    createdAt,
                    combinations,
                    isProductUnique,
                  } = productGroup;
                  const format = "DD-MM-YYYY h:mmA";
                  const formattedDate = moment(createdAt).format(format);
                  return (
                    <tr key={_id}>
                      <td>{index + 1}</td>
                      <td
                        onClick={() =>
                          navigate(`/inventory/product-group/${_id}`)
                        }
                      >
                        <div className="item_d">{formattedDate}</div>
                      </td>
                      <td
                        onClick={() =>
                          navigate(`/inventory/product-group/${_id}`)
                        }
                      >
                        <div className="item_name">
                          {groupName && (
                            <Tooltip title={groupName}>
                              {shortenText(groupName, 12)}
                            </Tooltip>
                          )}
                        </div>
                      </td>
                      <td
                        onClick={() =>
                          navigate(`/inventory/product-group/${_id}`)
                        }
                      >
                        <div className="item_name">
                          <Tooltip title={category}>
                            {shortenText(category, 16)}
                          </Tooltip>
                        </div>
                      </td>

                      <td
                        onClick={() =>
                          navigate(`/inventory/product-group/${_id}`)
                        }
                      >
                        {isProductUnique ? combinations.length : ""}
                      </td>

                      <td
                        onClick={() =>
                          navigate(`/inventory/product-group/${_id}`)
                        }
                      >
                        <div className="item_name">
                          <Tooltip title={description}>
                            {shortenText(description, 14)}
                          </Tooltip>
                        </div>
                      </td>

                      <td className="icons actions">
                        <span>
                          <Link to={`/inventory/product-group/${_id}`}>
                            {/* <FaEdit size={20} color={"green"} /> */}
                            <img src={editIcon} alt="edit" />
                          </Link>
                        </span>{" "}
                        &nbsp;
                        {admin || currentUser?.permissions?.deleteProducts ? (
                          <span>
                            <img
                              onClick={() => confirmDelete(_id)}
                              src={deleteIcon}
                              alt="delete"
                            />
                          </span>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="activePageClass"
        />
      </div>
    </div>
  );
};

export default ProductGroupList;
