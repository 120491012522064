import { useState, useRef, useEffect, useCallback, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProductGroups,
  createMultipleProducts,
  updateProductGroup,
  selectIsLoading,
  selectDraft,
  getDraft,
  saveDraft,
} from "../redux/features/product/productSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  selectIsLoggedIn,
  selectUser,
  selectLoggedInBusinessOwner,
} from "../redux/features/auth/authSlice";

// Initial state for form data
const initialState = {
  groupName: "",
  category: "",
  description: "",
  isProductUnique: false,
  cost: [],
  price: [],
  sku: [],
  warehouse: [],
  attributes: [],
  options: {},
  combinations: [],
  quantity: [],
  image: "",
};

// SKU Generation Function
const generateSKU = (groupName, combination) => {
  let part1 = combination.split("-");
  if (part1.length < 2) return "";
  let part2 = part1[1].split("/");

  const letter = part1[0].trim();
  const number = part2[0].trim().slice(-4);
  const sku = `${letter}-${number}`;
  return sku;
};

const validateFormData = (formData, setFormData) => {
  const errors = {};

  // Check required fields
  if (!formData.groupName || formData.groupName.trim() === "") {
    errors.groupName = "Group name is required.";
  }

  if (!formData.category || formData.category.trim() === "") {
    errors.category = "Category is required.";
  }

  if (!formData.description || formData.description.trim() === "") {
    errors.description = "Description is required.";
  }

  // Validate cost, price, and SKU arrays
  if (!Array.isArray(formData.cost) || formData.cost.length === 0) {
    errors.cost = "Cost is required and must be an array.";
  }

  if (!Array.isArray(formData.price) || formData.price.length === 0) {
    errors.price = "Price is required and must be an array.";
  }

  if (!Array.isArray(formData.sku) || formData.sku.length === 0) {
    errors.sku = "SKU is required and must be an array.";
  } else if (
    !formData.sku.every((sku) => typeof sku === "string" && sku.trim() !== "")
  ) {
    errors.sku = "All SKU values must be non-empty strings.";
  }

  // Validate warehouse (array of objects)
  if (!Array.isArray(formData.warehouse) || formData.warehouse.length === 0) {
    errors.warehouse = "Warehouse data is required and must be an array.";
  }

  // Validate quantity (array of numbers)
  if (!Array.isArray(formData.quantity) || formData.quantity.length === 0) {
    errors.quantity = "Quantity is required and must be an array.";
  } else {
    if (
      !formData.quantity.every((qty) => typeof qty === "number" && qty >= 0)
    ) {
      const updatedQuantity = formData.quantity.map((qty) =>
        typeof qty === "number" ? qty : parseInt(qty, 10)
      );
      setFormData((prevFormData) => ({
        ...prevFormData,
        quantity: updatedQuantity,
      }));
    }
  }

  // Validate attributes (array of objects)
  if (!Array.isArray(formData.attributes)) {
    errors.attributes = "Attributes must be an array.";
  }

  // Validate combinations (array of objects)
  if (!Array.isArray(formData.combinations)) {
    errors.combinations = "Combinations must be an array.";
  }

  // Check if there are any errors
  const isValid = Object.keys(errors).length === 0;

  return { isValid, errors };
};

const useProductGroup = ({ mode, id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Selectors
  const isLoading = useSelector(selectIsLoading);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const draft = useSelector(selectDraft);
  const allProductGroups = useSelector(
    (state) => state.allProductGroups.allProductGroups
  );
  const currentUser = useSelector(selectUser);
  const admin = useSelector(selectLoggedInBusinessOwner);

  // State Management
  const [formData, setFormData] = useState(initialState);
  const [attributes, setAttributes] = useState([""]);
  const [combinations, setCombinations] = useState([]);
  const [quantity, setQuantity] = useState([]);
  const [sku, setSku] = useState([]);
  const [price, setPrice] = useState([]);
  const [warehouse, setWarehouse] = useState([]);
  const [cost, setCost] = useState([]);
  const [productImage, setProductImage] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [draftId, setDraftId] = useState(null);
  const [options, setOptions] = useState(() =>
    attributes.map(() => ({
      attr: [{ value: "", showInput: true }],
    }))
  );

  // Refs for input fields
  const inputRefs = useRef([]);

  function removeFromCombined(array, combinationToRemove, indexToRemove) {
    const arrayCopy = JSON.parse(JSON.stringify(array));

    if (combinationToRemove && indexToRemove >= 0) {
      arrayCopy.forEach((optionGroup) => {
        const attrArray = optionGroup.attr;

        if (indexToRemove < attrArray.length) {
          attrArray.splice(indexToRemove + 1, 1);
        }
      });

      return arrayCopy;
    }
    return arrayCopy;
  }

  // Combine Multiple Options Function
  const combineMultipleOptions = useCallback(
    (...optionsArrays) => {
      if (!formData.isProductUnique) {
        // combination of options #2
        const combinedArrays = [];

        function generate(index, currentCombination) {
          if (index === optionsArrays.length) {
            combinedArrays.push(currentCombination);
            return;
          }

          if (optionsArrays[index].length === 0) {
            generate(index + 1, currentCombination);
          } else {
            optionsArrays[index].forEach((element) => {
              generate(index + 1, [...currentCombination, element]);
            });
          }
        }

        generate(0, []);

        // return combinedArrays.map((combination) => combination.join("/"));
        return combinedArrays.map((combination) => {
          const combinedOption = combination.join("/");
          return formData.groupName
            ? `${formData.groupName} - ${combinedOption}`
            : combinedOption;
        });
      } else {
        // combination of options #1
        const maxLength = Math.max(...optionsArrays.map((arr) => arr.length));
        const combined = [];

        for (let i = 0; i < maxLength; i++) {
          const combinedOption = formData.groupName
            ? `${formData.groupName} - ${optionsArrays
                .map((arr) => arr[i] || "")
                .join("/")}`
            : optionsArrays.map((arr) => arr[i] || "").join("/");

          combined.push(combinedOption);
        }

        return combined;
      }
    },
    [formData.isProductUnique, formData.groupName]
  );

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      options: options,
    }));
  }, [options]);

  // Initialize inputRefs based on attributes
  useEffect(() => {
    // Ensure options are properly synced with attributes
    setOptions((prevOptions) => {
      const updatedOptions = attributes.map(
        (_, i) =>
          prevOptions[i] || {
            attr: [{ value: "", showInput: true }],
          }
      );
      return updatedOptions;
    });

    inputRefs.current = attributes.map(
      (_, i) => inputRefs.current[i] || createRef()
    );
  }, [attributes]);

  // Fetch all product groups on mount if logged in
  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getAllProductGroups());
    }
  }, [isLoggedIn, dispatch]);

  // Initialize form data for edit mode
  useEffect(() => {
    if (mode === "edit" && id && allProductGroups.length > 0) {
      const productToEdit = allProductGroups.find(
        (product) => product._id === id
      );
      if (productToEdit) {
        setFormData({
          groupName: productToEdit.groupName,
          category: productToEdit.category,
          description: productToEdit.description,
          cost: productToEdit.cost,
          price: productToEdit.price,
          sku: productToEdit.sku,
          isProductUnique: productToEdit.isProductUnique,
          warehouse: productToEdit.warehouse,
          attributes: productToEdit.attributes,
          options: productToEdit.options,
          combinations: productToEdit.combinations,
          quantity: productToEdit.quantity,
          image: productToEdit.image,
        });
        setAttributes(productToEdit.attributes);
        setSku(productToEdit.sku);
        setPrice(productToEdit.price);
        setCombinations(productToEdit.combinations);
        setCost(productToEdit.cost);
        setWarehouse(productToEdit.warehouse);
        setOptions(productToEdit.options);
        setQuantity(productToEdit.quantity);
      }
    }
  }, [mode, id, allProductGroups]);

  useEffect(() => {
    const updatedOptionsArrays = Object.values(options).map((option) =>
      option.attr.map((opt) => opt.value).filter((value) => value !== "")
    );

    const combinedOptions = combineMultipleOptions(...updatedOptionsArrays);

    setCombinations(combinedOptions);

    // Update quantity array to match the length of combinations if isProductUnique is true
    setQuantity((prev) =>
      formData.isProductUnique ? Array(combinedOptions.length).fill(1) : prev
    );

    setFormData((prev) => ({
      ...prev,
      combinations: combinedOptions,
      quantity: formData.isProductUnique
        ? Array(combinedOptions.length).fill(1)
        : prev.quantity,
    }));
  }, [options, combineMultipleOptions, formData.isProductUnique]);

  // Update combinations whenever options change considering isProductUnique
  useEffect(() => {
    const updatedOptionsArrays = Object.values(options).map((option) =>
      option.attr.map((opt) => opt.value).filter((value) => value !== "")
    );

    const combinedOptions = combineMultipleOptions(...updatedOptionsArrays);

    setCombinations(combinedOptions);
    setFormData((prev) => ({
      ...prev,
      combinations: combinedOptions,
    }));
  }, [options, combineMultipleOptions]);

  // Handlers

  const handleInputChange = useCallback(
    (e) => {
      const { name, type, checked, value } = e.target;
      setFormData((prev) => {
        // Check if isProductUnique is being updated
        if (name === "isProductUnique") {
          const newValue = type === "checkbox" ? checked : value;
          const newFormData = {
            ...prev,
            [name]: newValue,
          };

          // Regenerate combinations if isProductUnique is updated
          const updatedOptionsArrays = Object.values(newFormData.options).map(
            (option) =>
              option.attr
                .map((opt) => opt.value)
                .filter((value) => value !== "")
          );
          const combinedOptions = combineMultipleOptions(
            ...updatedOptionsArrays
          );

          // Reset cost, price, quantity, and warehouse arrays to empty arrays
          const newSku = [];
          const newCost = [];
          const newPrice = [];
          const newWarehouse = [];

          const newQuantity = newValue
            ? Array(combinedOptions.length).fill(1)
            : [];

          // Update state
          setSku(newSku);
          setPrice(newPrice);
          setCost(newCost);
          setQuantity(newQuantity);
          setWarehouse(newWarehouse);

          return {
            ...newFormData,
            combinations: combinedOptions,
            sku: newSku,
            cost: newCost,
            price: newPrice,
            quantity: newQuantity,
            warehouse: newWarehouse,
          };
        }
        return {
          ...prev,
          [name]: type === "checkbox" ? checked : value,
        };
      });
    },
    [combineMultipleOptions, combinations]
  );

  // Handle Blur on Attribute Inputs
  const handleBlur = useCallback(
    (e, index) => {
      const { value } = e.target;
      const updatedAttributes = [...attributes];
      updatedAttributes[index] = value;
      setAttributes(updatedAttributes);
      setFormData((prev) => ({
        ...prev,
        attributes: updatedAttributes,
      }));
    },
    [attributes]
  );

  // Handle Key Press in Options Inputs
  const handleKeyPress = useCallback((e, attrIndex) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      const inputValue = e.target.value.trim();
      if (inputValue === "") return;

      // Check for forbidden characters ("/" or "-")
      if (inputValue === "" || /[\/-]/.test(inputValue)) {
        toast.error('Please remove characters for "-" or "/"');
        return;
      }

      // Clear input field
      e.target.value = "";

      setOptions((prevOptions) => {
        const newOptions = JSON.parse(JSON.stringify(prevOptions));
        newOptions[attrIndex].attr.push({
          value: inputValue,
          showInput: false,
        });

        // Also update formData here
        setFormData((prev) => ({
          ...prev,
          options: newOptions,
        }));

        return newOptions;
      });
    }
  }, []);

  // Handle Adding a New Attribute
  const handleAddAttribute = useCallback(() => {
    setAttributes((prev) => [...prev, ""]);
    setOptions((prevOptions) => ({
      ...prevOptions,
      [attributes.length]: {
        attr: [{ value: "", showInput: true }],
      },
    }));
  }, [attributes.length]);

  // Handle Deleting an Attribute
  const handleDeleteAttribute = useCallback(
    (index) => {
      // Step 1: Remove the attribute
      const updatedAttributes = [...attributes];
      updatedAttributes.splice(index, 1);
      setAttributes(updatedAttributes);

      // Step 2: Remove the corresponding options for the deleted attribute
      const updatedOptions = { ...options };
      delete updatedOptions[index];

      // Step 3: Re-index the remaining options to ensure proper mapping
      const reorderedOptions = {};
      Object.keys(updatedOptions).forEach((key) => {
        const newKey = key > index ? key - 1 : key; // Shift the keys after the deleted one
        reorderedOptions[newKey] = updatedOptions[key];
      });
      setOptions(reorderedOptions);

      // Step 4: Re-generate combinations based on the remaining options
      const updatedOptionsArrays = Object.values(reorderedOptions).map(
        (option) =>
          option.attr.map((opt) => opt.value).filter((value) => value !== "")
      );
      const newCombinations = combineMultipleOptions(...updatedOptionsArrays);

      // Re-generate SKUs based on the new combinations
      const newSku = newCombinations.map((combination) =>
        generateSKU(formData.groupName, combination)
      );

      // Step 5: Update SKU, Price, Cost, Warehouse based on the new combinations
      const newPrice = price.slice(0, newCombinations.length);
      const newCost = cost.slice(0, newCombinations.length);
      const newWarehouse = warehouse.slice(0, newCombinations.length);

      // Step 6: Update state
      setCombinations(newCombinations);
      setSku(newSku);
      setPrice(newPrice);
      setCost(newCost);
      setWarehouse(newWarehouse);

      // Step 7: Update formData accordingly
      setFormData((prev) => ({
        ...prev,
        attributes: updatedAttributes,
        options: reorderedOptions,
        combinations: newCombinations,
        sku: newSku,
        price: newPrice,
        cost: newCost,
        warehouse: newWarehouse,
      }));
    },
    [attributes, options, sku, price, cost, warehouse, combineMultipleOptions]
  );

  // Handle Removing an Option Item
  const handleRemoveItem = useCallback(
    (attrIndex, optionIndex) => {
      setOptions((prevOptions) => {
        const newOptions = JSON.parse(JSON.stringify(prevOptions));
        newOptions[attrIndex].attr.splice(optionIndex, 1);

        // Update formData here
        setFormData((prev) => ({
          ...prev,
          options: newOptions,
        }));

        return newOptions;
      });

      // Adjust SKU, Price, Cost, Warehouse based on new combinations...
    },
    [combinations.length, cost, price, sku, warehouse]
  );

  // Handle Deleting a Combination
  const handleDeleteCombination = (combination) => {
    const index = combinations.indexOf(combination);

    if (index === -1) return;

    // Remove the combination from the list
    const newCombinations = combinations.filter((_, i) => i !== index);

    // Remove the corresponding values from SKU, Price, Cost, and Warehouse arrays
    const newSku = sku.filter((_, i) => i !== index);
    const newPrice = price.filter((_, i) => i !== index);
    const newCost = cost.filter((_, i) => i !== index);
    const newWarehouse = warehouse.filter((_, i) => i !== index);
    const newQuantity = quantity.filter((_, i) => i !== index);

    // Update options based on the new combination list if isProductUnique is true
    const newOptions = formData.isProductUnique
      ? removeFromCombined(options, combination, index)
      : options;

    // Update state
    setSku(newSku);
    setPrice(newPrice);
    setCost(newCost);
    setQuantity(newQuantity);
    setWarehouse(newWarehouse);
    setCombinations(newCombinations);
    setOptions(newOptions);

    // Update formData
    setFormData((prevFormData) => ({
      ...prevFormData,
      combinations: newCombinations,
      sku: newSku,
      price: newPrice,
      cost: newCost,
      quantity: newQuantity,
      warehouse: newWarehouse,
      options: newOptions,
    }));
  };

  // Handle Setting Combination Name
  const handleSetCombinationName = useCallback(
    (e, index) => {
      const updatedCombinations = [...combinations];
      updatedCombinations[index] = e.target.value;
      setCombinations(updatedCombinations);
      setFormData((prev) => ({
        ...prev,
        combinations: updatedCombinations,
      }));
    },
    [combinations]
  );

  // Handle SKU Change
  const handleSkuChange = useCallback(
    (e, index) => {
      const updatedSku = [...sku];
      updatedSku[index] = e.target.value;
      setSku(updatedSku);
      setFormData((prev) => ({
        ...prev,
        sku: updatedSku,
      }));
    },
    [sku]
  );

  // Handle Price Change
  const handlePriceChange = useCallback(
    (e, index) => {
      const { value } = e.target;
      const regex = /^[0-9]*$/; // Only allow numbers
      if (regex.test(value)) {
        const updatedPrice = [...price];
        updatedPrice[index] = value;
        setPrice(updatedPrice);
        setFormData((prev) => ({
          ...prev,
          price: updatedPrice,
        }));
      } else {
        toast.error("Only numbers are allowed.");
        e.target.value = "";
      }
    },
    [price]
  );

  // Handle Quantity Change
  const handleQuantityChange = useCallback(
    (e, index) => {
      const { value } = e.target;
      const regex = /^[0-9]*$/; 
      
      if (regex.test(value)) {
        const updatedQuantity = [...quantity];
        updatedQuantity[index] = value;
        
        setQuantity(updatedQuantity);
        setFormData((prev) => ({
          ...prev,
          quantity: updatedQuantity,
        }));
      } else {
        toast.error("Only numbers are allowed.");
        e.target.value = ""; // Reset the field if invalid
      }
    },
    [quantity]
  );

  // Handle Cost Change
  const handleCostChange = useCallback(
    (e, index) => {
      const { value } = e.target;
      const regex = /^[0-9]*$/; // Only allow numbers
      if (regex.test(value)) {
        const updatedCost = [...cost];
        updatedCost[index] = value;
        setCost(updatedCost);
        setFormData((prev) => ({
          ...prev,
          cost: updatedCost,
        }));
      } else {
        toast.error("Only numbers are allowed.");
        e.target.value = "";
      }
    },
    [cost]
  );

  // Handle Warehouse Change
  const handleWarehouseChange = useCallback(
    (e, index) => {
      const updatedWarehouse = [...warehouse];
      updatedWarehouse[index] = e.target.value;
      setWarehouse(updatedWarehouse);
      setFormData((prev) => ({
        ...prev,
        warehouse: updatedWarehouse,
      }));
    },
    [warehouse]
  );

  // Handle Copy SKU to All
  const handleCopySkuToAll = useCallback(() => {
    const updatedSku = combinations.map((comb) =>
      generateSKU(formData.groupName, comb)
    );
    setSku(updatedSku);
    setFormData((prev) => ({
      ...prev,
      sku: updatedSku,
    }));
  }, [combinations, formData.groupName]);

  const handleCopyQuantityToAll = useCallback(() => {
    if (quantity.length === 0) return;
    const baseQuantity = quantity[0];
    const updatedQuantity = combinations.map(() => baseQuantity);
    setQuantity(updatedQuantity);
    setFormData((prev) => ({
      ...prev,
      quantity: updatedQuantity,
    }));
  }, [combinations, quantity]);

  // Handle Copy Cost to All
  const handleCopyCostToAll = useCallback(() => {
    if (cost.length === 0) return;
    const baseCost = cost[0];
    const updatedCost = combinations.map(() => baseCost);
    setCost(updatedCost);
    setFormData((prev) => ({
      ...prev,
      cost: updatedCost,
    }));
  }, [combinations, cost]);

  // Handle Copy Price to All
  const handleCopyPriceToAll = useCallback(() => {
    if (price.length === 0) return;
    const basePrice = price[0];
    const updatedPrice = combinations.map(() => basePrice);
    setPrice(updatedPrice);
    setFormData((prev) => ({
      ...prev,
      price: updatedPrice,
    }));
  }, [combinations, price]);

  // Handle Copy Warehouse to All
  const handleCopyWarehouseToAll = useCallback(() => {
    if (warehouse.length === 0) return;
    const baseWarehouse = warehouse[0];
    const updatedWarehouse = combinations.map(() => baseWarehouse);
    setWarehouse(updatedWarehouse);
    setFormData((prev) => ({
      ...prev,
      warehouse: updatedWarehouse,
    }));
  }, [combinations, warehouse]);

  // console.log("Formdata Used", formData);

  // Save Product Group
  const saveProductGroup = useCallback(
    async (e) => {
      e.preventDefault();

      const { isValid, errors } = validateFormData(formData, setFormData);

      console.log("errors", errors);

      // If validation fails, show errors and stop the process
      if (!isValid) {
        console.error("Validation errors:", errors);
        errors.forEach((error) => toast.error(error));
        return;
      }

      const formDataToSend = new FormData();

      formDataToSend.append("groupName", formData.groupName);
      formDataToSend.append("category", formData.category);
      formDataToSend.append("description", formData.description);
      formDataToSend.append(
        "isProductUnique",
        JSON.stringify(formData.isProductUnique)
      );

      formDataToSend.append("cost", JSON.stringify(formData.cost));
      formDataToSend.append("price", JSON.stringify(formData.price));
      formDataToSend.append("sku", JSON.stringify(formData.sku));
      formDataToSend.append("quantity", JSON.stringify(formData.quantity));

      formDataToSend.append("warehouse", JSON.stringify(formData.warehouse));
      formDataToSend.append("attributes", JSON.stringify(formData.attributes));
      formDataToSend.append(
        "combinations",
        JSON.stringify(formData.combinations)
      );

      // For options object
      formDataToSend.append("options", JSON.stringify(formData.options));
      formDataToSend.append("image", productImage);

      // Save Logic
      try {
        if (mode === "add") {
          await dispatch(createMultipleProducts(formDataToSend));
          toast.success("Product group added successfully!");
          navigate("/inventory/product-groups");
        } else if (mode === "edit") {
          await dispatch(updateProductGroup({ id, formData: formDataToSend }));
          toast.success("Product group updated successfully!");
          navigate("/inventory/product-groups");
        }
      } catch (error) {
        toast.error("An error occurred while saving the product group.");
        console.error(error);
      }
    },
    [formData, dispatch, navigate, mode, id, options, productImage]
  );

  const focusInput = useCallback((ref) => {
    ref?.current?.focus();
  }, []);

  const handleImageChange = (e) => {
    setProductImage(e.target.files[0]);
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  };

  return {
    // States
    isLoading,
    admin,
    currentUser,
    formData,
    attributes,
    combinations,
    sku,
    price,
    quantity,
    warehouse,
    cost,
    options,
    inputRefs,
    productImage,
    imagePreview,

    // Handlers
    handleInputChange,
    handleBlur,
    handleKeyPress,
    handleAddAttribute,
    handleDeleteAttribute,
    handleRemoveItem,
    handleDeleteCombination,
    handleSetCombinationName,
    handleSkuChange,
    handlePriceChange,
    handleCostChange,
    handleWarehouseChange,
    handleQuantityChange,
    handleCopySkuToAll,
    handleCopyCostToAll,
    handleCopyPriceToAll,
    handleCopyWarehouseToAll,
    handleCopyQuantityToAll,
    saveProductGroup,
    focusInput,
    handleImageChange,
  };
};

export default useProductGroup;
