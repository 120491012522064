import { useEffect, useState, useRef } from "react";
import { SpinnerImg } from "../../loader/Loader";
import styled from "styled-components";
import "./productList.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  FILTER_PRODUCTS,
  selectFilteredPoducts,
} from "../../../redux/features/product/filterSlice";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  deleteProduct,
  getOutOfStock,
  getProducts,
  getSales,
  sellProduct,
} from "../../../redux/features/product/productSlice";
import { addToCart, getCart } from "../../../redux/features/cart/cartSlice";
import { Tooltip, Modal, InputNumber, message } from "antd";
import { Link } from "react-router-dom";
import deleteIcon from "../../../assets/home/delete-icon.svg";
import editIcon from "../../../assets/home/edit-icon.svg";
import InventoryHeader from "../../inventoryHeader/InventoryHeader";
import { selectUser } from "../../../redux/features/auth/authSlice";
import xcrossIcon from "../../../assets/home/xcrossIcon.svg";

const CustomModal = styled(Modal)`
  .ant-modal-close {
    color: #4caf50;
  }
`;

const ProductList = ({ products, isLoading, admin, activeRoute }) => {
  const [search, setSearch] = useState("");
  const filteredProducts = useSelector(selectFilteredPoducts);
  const dispatch = useDispatch();
  const currentUser = useSelector(selectUser);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productQuantity, setProductQuantity] = useState(1);

  // const handleShowModal = (product) => {
  //   setSelectedProduct(product);
  //   setProductQuantity(1);
  //   setIsModalVisible(true);
  // };

  const handleShowModal = async (product) => {
    if (product.quantity === 1 || product.quantity === "1") {
      const formData = {
        product: {
          ...product,
          quantity: "1",
        },
        user: currentUser,
      };

      const id = product._id;
      await dispatch(addToCart({ id, formData }));
      await dispatch(getCart(currentUser.email));

      // message.success(`${product.name} added to cart successfully!`);
    } else {
      setSelectedProduct(product);
      setProductQuantity(1);
      setIsModalVisible(true);
    }
  };

  const handleModalOk = async () => {
    if (selectedProduct) {
      const formData = {
        product: {
          ...selectedProduct,
          quantity: String(productQuantity),
        },
        user: currentUser,
      };

      // console.log("Form Data", formData);

      const id = selectedProduct._id;
      await dispatch(addToCart({ id, formData }));
      await dispatch(getCart(currentUser.email));
    }
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleQuantityChange = (value) => {
    setProductQuantity(value);
  };

  const shortenText = (text, n) => {
    if (text.length > n) {
      const shortenedText = text.substring(0, n).concat("...");
      return shortenedText;
    }
    return text;
  };

  // console.log("Current User", currentUser);

  const delProduct = async (id) => {
    await dispatch(deleteProduct(id));
    await dispatch(getProducts());
  };

  //   Begin Pagination
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;
  const [showProductImage, setShowProductImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(filteredProducts.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(filteredProducts.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, filteredProducts]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % filteredProducts.length;
    setItemOffset(newOffset);
  };

  const confirmDelete = (id) => {
    confirmAlert({
      title: "Delete Product",
      message: "Are you sure you want to delete this product.",
      buttons: [
        {
          label: "Delete",
          onClick: () => delProduct(id),
        },
        {
          label: "Cancel",
          // onClick: () => alert('Click No')
        },
      ],
    });
  };

  useEffect(() => {
    dispatch(FILTER_PRODUCTS({ products, search }));
  }, [products, search, dispatch]);

  // const handleAddToCart = async (product) => {
  //   const formData = {
  //     product: {
  //       name: product?.name,
  //       cost: product?.cost,
  //       quantity: "1",
  //       price: product?.price,
  //       id: product?._id,
  //       description: product?.description,
  //       sku: product?.sku,
  //       productIsaGroup: product?.productIsaGroup ? true : false,
  //       itemGroup: product?.itemGroup,
  //       category: product?.category,
  //       warehouse: product?.warehouse,
  //     },
  //     user: currentUser,
  //   };

  //   const id = product._id;
  //   await dispatch(addToCart({ id, formData }));
  //   await dispatch(getCart(currentUser.email));
  // };

  const handleShowProductImage = (imagePath) => {
    setSelectedImage(imagePath);
    setShowProductImage(true);
  };

  const closeProductImage = () => {
    setShowProductImage(false);
    setSelectedImage(null);
  };

  const formatter = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    }).format(amount);
  };

  return (
    <>
      <div className="product-list">
        <div className="table">
          <InventoryHeader
            search={search}
            handleSearchChange={(e) => setSearch(e.target.value)}
          />

          {isLoading && <SpinnerImg />}

          <div className="table">
            {!isLoading && products.length === 0 ? (
              <p className="no-products-p">
                -- No product found, please add a product...
              </p>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th>s/n</th>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Category</th>
                    <th>Warehouse</th>
                    <th>Selling Price</th>
                    <th>Quantity left</th>
                    <th>Total Value</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {currentItems.map((product, index) => {
                    const {
                      _id,
                      name,
                      category,
                      price,
                      quantity,
                      cost,
                      warehouse,
                      description,
                      sku,
                      productIsaGroup,
                      itemGroup,
                      image,
                    } = product;
                    return (
                      <tr key={_id}>
                        <td>{index + 1}</td>
                        <td>
                          {image && (
                            <>
                              <img
                                onClick={() =>
                                  handleShowProductImage(image?.filePath)
                                }
                                className="product_img"
                                src={image?.filePath}
                                alt="product image"
                              />
                              {showProductImage && (
                                <div className="show_product_image">
                                  <div
                                    className="close_btn"
                                    onClick={closeProductImage}
                                  >
                                    <img src={xcrossIcon} alt="close" />
                                  </div>
                                  <div className="product_image_preview">
                                    <img
                                      src={selectedImage}
                                      alt="enlarged product"
                                    />
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </td>
                        <td>
                          <div className="item_name">
                            <Tooltip title={name}>
                              {shortenText(name, 16)}
                            </Tooltip>
                          </div>
                        </td>
                        <td>
                          <div className="item_name">
                            <Tooltip title={category}>
                              {shortenText(category, 16)}
                            </Tooltip>
                          </div>
                        </td>
                        <td>
                          <div className="item_name">
                            {warehouse && (
                              <Tooltip title={warehouse}>
                                {shortenText(warehouse, 16)}
                              </Tooltip>
                            )}
                          </div>
                        </td>
                        <td>{formatter(price)}</td>
                        <td>
                          <div className="item_q">
                            <Tooltip title={quantity}>
                              {shortenText(quantity, 16)}
                            </Tooltip>
                          </div>
                        </td>
                        <td>{formatter(price * quantity)}</td>
                        <td className="icons">
                          <div>
                            {admin || currentUser?.permissions?.sellProducts ? (
                              <div>
                                {Number(quantity) !== 0 ||
                                Number(quantity) > 0 ? (
                                  <span>
                                    <button
                                      type="button"
                                      className="td-sell-btn"
                                      // onClick={() => handleAddToCart(product)}
                                      onClick={() => handleShowModal(product)}
                                    >
                                      Add
                                    </button>
                                  </span>
                                ) : null}
                              </div>
                            ) : null}

                            {admin || currentUser?.permissions?.editproducts ? (
                              <span>
                                {!productIsaGroup && (
                                  <Link to={`/edit-product/${_id}`}>
                                    <img src={editIcon} alt="edit" />
                                  </Link>
                                )}
                              </span>
                            ) : null}

                            {admin ||
                            currentUser?.permissions?.deleteProducts ? (
                              <span>
                                {!productIsaGroup && (
                                  <img
                                    onClick={() => confirmDelete(_id)}
                                    src={deleteIcon}
                                    alt="delete"
                                  />
                                )}
                              </span>
                            ) : null}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>

          <CustomModal
            title={`Add ${selectedProduct?.name} to Cart`}
            visible={isModalVisible}
            onOk={handleModalOk}
            onCancel={handleModalCancel}
            okText="Add to Cart"
            cancelText="Cancel"
            okButtonProps={{
              className: "custom-ok-button",
            }}
            cancelButtonProps={{
              className: "custom-cancel-button",
            }}
          >
            <p>Select quantity:</p>
            <InputNumber
              min={1}
              max={selectedProduct?.quantity || 1} // Maximum is the available stock
              value={productQuantity}
              onChange={handleQuantityChange}
            />
          </CustomModal>

          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="page-num"
            previousLinkClassName="page-num"
            nextLinkClassName="page-num"
            activeLinkClassName="activePageClass"
          />
        </div>
      </div>
    </>
  );
};

export default ProductList;
