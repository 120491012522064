import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Search from "../search/Search";

export default function InventoryHeader({search, handleSearchChange}) {
//   const [search, setSearch] = useState("");
  const [activeRoute, setActiveRoute] = useState("");

  const { id } = useParams(); 

  useEffect(() => {
    setActiveRoute(id)
  }, [id])
 
  return (
    <div className="--flex-between defaul-inventory-header">
      <span>
        <h3>
          <Link
            className={
              activeRoute === undefined ? "inventory-routes active-route" : "inventory-routes"
            }
            to="/inventory"
          >
            Products
          </Link>
          <Link
            className={
              activeRoute === "product-groups" ? "inventory-routes active-route" : "inventory-routes"
            }
            to="/inventory/product-groups"
          >
            Groups
          </Link>
          <Link
            className={
              activeRoute === "sales-checkin-new" ? "inventory-routes active-route" : "inventory-routes"
            }
            to={`/inventory/sales-checkin-new`}
          >
            Sales
          </Link>
          <Link
            className={
              activeRoute === "out-of-stock" ? "inventory-routes active-route" : "inventory-routes"
            }
            to={`/inventory/out-of-stock`}
          >
            Out of Stock
          </Link>
        </h3>
      </span>
      <span className="inventory_header_search">
        <Search value={search} onChange={handleSearchChange} />
      </span>
    </div>
  );
}
